primary-background {
  background: #0057b8;
}

.primary-background {
  .dashboard-btn {
    color: black !important;
    background-color: white;
  }

  .dashboard-btn:hover {
    color: black !important;
  }

  .dashboard-btn:focus {
    color: black !important;
  }
}

.tab-content {
  width: 100% !important
}

// Buttons
.dashboard-btn {
  background-color: #0057b8;;
  border-color: #0057b8;
  color: white;
}

.dashboard-btn:focus {
  outline-color: #1c66d6 !important;
  color: white !important;
}

.dashboard-btn:hover {
  color: white !important;
}

.jumbotron {
  background: linear-gradient(to bottom, #F0F3F8 0%, #F0F3F8 85%, #FFFFFF 85%, #FFFFFF 100%) !important;
}
