// Custom.scss
$theme-colors: (
  "swift-primary": #2E3C63,
  "swift-secondary": #2E3C63,
  "swift-grey": #F0F3F8,
  "footer-grey": rgba(74, 90, 133, 0.05),
  "no-progress": #CBCED8,
  "swift-button": #0057B8,
  "swift-back": #202b45
);




// Required
@import "node_modules/bootstrap/scss/bootstrap";
