.footerTitle {
  margin-left: 1.5em;
}

.footerCR {
  margin-left: 1.5em;
}

.footerContact {
  // margin-left: 40%;
  //
  // margin-right: auto;
}

.test {
  position: relative;
  bottom: -4em;
  width: 100%;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}